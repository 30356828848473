// Packages
import React, { useState } from "react"

//Assets
import { ReactComponent as RotateHorizontal } from "@Assets/bottombarIcons/rotateHorizontal.svg"
import { ReactComponent as RotateVertical } from "@Assets/bottombarIcons/rotateVertical.svg"
import { ReactComponent as Ruler } from "@Assets/bottombarIcons/ruler.svg"
import { ReactComponent as Border } from "@Assets/bottombarIcons/border.svg"
import { ReactComponent as Guides } from "@Assets/bottombarIcons/guides.svg"
import { ReactComponent as FullScreen } from "@Assets/bottombarIcons/fullScreen.svg"

//Components
import Slider from "@Components/inputs/Slider"
import Layers from "@Components/bottombarComponents/Layers"

//utils
import { handlePrintBleed, handleRotate } from "@Utils/footer/Footer"

function BottomBar(props) {
  const {
    canvas,
    borderAction,
    zoomIn,
    setZoomValue,
    zoomValue,
    printBleed,
    guides,
    ruler,
    handleChangeRulerClicked,
    handleChangeGuidesClicked,
    setCanvasWidth,
    setCanvasHeight,
    lastBackgroundImage,
    canvasType,
    canvasHeight,
    canvasWidth,
    setLastBackgroundImage,
    layerList,
    setLayerList,
    handleFullscreen,
    setRuler,
    selectedObjects,
    setSharedStyling,
    setZoomValueBeforeFullscreen,
    printBleedImg,
    sharedStyling,
    canvasLocation,
    isFilterSelected,
    initialRatio
  } = props
  const [orientation, setOrientation] = useState(
    canvasLocation ? canvasLocation : "portrait"
  )

  return (
    <div>
      <div className="bg-grayEFEFEF h-20 flex w-full justify-between items-center px-8">
        <div className="flex space-x-3">
          <Ruler
            className={`${ruler ? "fill-selected" : "fill-gray"
              } hover:fill-selected cursor-pointer`}
            onClick={handleChangeRulerClicked}
          />
          <Guides
            className={`${guides ? "fill-selected" : "fill-gray"
              } hover:fill-selected cursor-pointer`}
            onClick={handleChangeGuidesClicked}
          />
          <Border
            className={`${printBleed ? "stroke-selected" : "stroke-gray"
              } hover:stroke-selected cursor-pointer`}
            onClick={(e) => {
              borderAction(e)
              handlePrintBleed(
                canvas,
                printBleedImg,
                canvasWidth,
                canvasHeight,
                printBleed
              )
            }}
          />
        </div>
        <div className="flex space-x-3">
          {canvasType === "portrait" ? (
            <RotateVertical
              className={`${canvasType === "portrait" ? "stroke-selected" : "stroke-gray"
                } hover:stroke-selected cursor-pointer`}
            />
          ) : canvasType === "landscape" ? (
            <RotateHorizontal
              className={`${canvasType === "landscape" ? "stroke-selected" : "stroke-gray"
                } hover:stroke-selected cursor-pointer self-end`}
            />
          ) : canvasType === "both" ? (
            <>
              <RotateVertical
                className={`${orientation === "portrait"
                  ? "stroke-selected"
                  : "stroke-gray cursor-pointer"
                  } hover:stroke-selected `}
                onClick={() => {
                  if (orientation === "landscape") {
                    handleRotate(
                      canvas,
                      setCanvasWidth,
                      setCanvasHeight,
                      canvasWidth,
                      canvasHeight,
                      printBleed,
                      printBleedImg,
                      lastBackgroundImage,
                      setLastBackgroundImage,
                      isFilterSelected
                    )
                    setOrientation((prev) => {
                      return prev === "portrait" ? "landscape" : "portrait"
                    })
                  }
                }}
              />
              <RotateHorizontal
                className={`${orientation === "landscape"
                  ? "stroke-selected "
                  : "stroke-gray cursor-pointer"
                  } hover:stroke-selected  self-end`}
                onClick={() => {
                  if (orientation === "portrait") {
                    handleRotate(
                      canvas,
                      setCanvasWidth,
                      setCanvasHeight,
                      canvasWidth,
                      canvasHeight,
                      printBleed,
                      printBleedImg,
                      lastBackgroundImage,
                      setLastBackgroundImage,
                      isFilterSelected
                    )
                    setOrientation((prev) => {
                      return prev === "portrait" ? "landscape" : "portrait"
                    })
                  }
                }}
              />
            </>
          ) : (
            canvasType === "hide" && <div />
          )}
        </div>
        <div className="flex  mr-16">
          <Layers
            canvas={canvas}
            layerList={layerList}
            selectedObjects={selectedObjects}
            setLayerList={setLayerList}
            setSharedStyling={setSharedStyling}
            sharedStyling={sharedStyling}
          />
          <Slider
            //multiply max and min just to avoid having negative values for length while changing slider value and calculating zoom scale
            max={initialRatio * 3}
            min={initialRatio / 10}
            onChange={(e) => {
              setZoomValue(e.target.value)
              zoomIn(
                canvas,
                e.target.value,
                canvasWidth,
                canvasHeight,
              )
            }}
            step={initialRatio / 10}
            unit={""}
            value={(Math.round(zoomValue * 100) / 100)}
            viewedValue={""}
          />

          {window.parent == window && (
            <FullScreen
              className="fill-gray hover:fill-selected cursor-pointer"
              onClick={() => {
                setZoomValueBeforeFullscreen(zoomValue)
                setZoomValue(800 / canvasWidth)
                zoomIn(
                  canvas,
                  800 / canvasWidth,
                  canvasWidth,
                  canvasHeight,
                )

                handleFullscreen()
                setRuler(false)
                canvas.forEachObject((object) => {
                  object.selectable = false
                  object.evented = false
                })
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BottomBar
