import { getLayers, handleDeleteLayer } from "../../helpers/GetLayers"

function imageProcessing(img, width, height, type) {
  // create an off-screen canvas
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d")

  // set its dimension to target size
  canvas.width = width
  canvas.height = height

  var img1 = new Image()
  img1.src = img

  img1.onload = () => {
    ctx.drawImage(img1, 0, 0, width, height)
    if (type === "download") {
      const link = document.createElement("a")
      link.download = "my-image.png"
      link.href = canvas.toDataURL()
      link.click()
    } else {
      let data = new Date().getTime()
      var file = dataURLtoFile(
        canvas.toDataURL(),
        `teelaunch-studio-${data}.png`
      )
      window.parent.postMessage({ file: file, status: "Done" }, "*")
    }
  }
}

export const handleDownload = (canvas, canvasWidth, canvasHeight) => {
  let layers = getLayers(canvas)

  layers = layers.filter(
    (layer) =>
      layer.name === "GuidelinesY" ||
      layer.name === "GuidelinesX" ||
      layer.name === "PrintBleed"
  )

  layers.map((layer) => handleDeleteLayer(canvas, layer))

  const image = canvas.toDataURL({
    format: "png",
    multiplier: canvasWidth / canvas.getWidth()
  })

  //resize and download image
  imageProcessing(image, canvasWidth, canvasHeight, "download")
}

export const handleNext = (
  canvas,
  canvasWidth,
  canvasHeight,
  setShowBackdrop,
  setNotification
) => {
  let layers = getLayers(canvas)

  layers = layers.filter(
    (layer) =>
      layer.name === "GuidelinesY" ||
      layer.name === "GuidelinesX" ||
      layer.name === "PrintBleed"
  )

  layers.map((layer) => handleDeleteLayer(canvas, layer))

  let emptyCanvas = document.createElement("canvas")
  emptyCanvas.width = canvasWidth
  emptyCanvas.height = canvasHeight

  const image = canvas.toDataURL({
    format: "png",
    multiplier: canvasWidth / canvas.getWidth()
  })

  if (image == emptyCanvas.toDataURL()) {
    setNotification({ text: "No Design Created", isOpen: true })
    setTimeout(() => {
      setNotification({ text: "", isOpen: false })
    }, 3000)
  } else {
    setShowBackdrop(true)
    setNotification({
      text: "Please Wait Until Studio Window Closes...",
      isOpen: true
    })
    setTimeout(() => {
      setNotification({ text: "", isOpen: false })
    }, 3000)
    //resize and send to teelaunch 2.0
    imageProcessing(image, canvasWidth, canvasHeight, "iframe")
  }
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: "image/png" })
}
