//Packages
import React from "react"

function Notification(props) {
  const { text, isOpen } = props
  return (
    <div>
      {isOpen && (
        <div
          className="absolute text-purple5F72BD bg-white  py-0.5 px-4 rounded-sm  top-4 right-1/2 transform translate-x-1/2 font-Rubik font-medium"
          style={{ zIndex: 1003 }}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default Notification
