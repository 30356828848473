//Alignments

/* Align the selected object */
function processAlign(canvas, val, activeObj, scale) {
  switch (val) {

    case 'alignLeft':
      var left;
      if (activeObj.angle <= 90) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.bl.x;
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.br.x;
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.tr.x;
      }
      if (activeObj.angle > 270) {
        left = 0;
      }
      activeObj.set({
        left: left
      });
      break;
    case 'alignRight':
      var left;
      if (activeObj.angle <= 90) {
        left = activeObj.aCoords.tl.x + (canvas.width / scale - activeObj.aCoords.tr.x);
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        left = canvas.width / scale;
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        left = activeObj.aCoords.tl.x + ((canvas.width / scale) - activeObj.aCoords.bl.x);
      }
      if (activeObj.angle > 270) {
        left = activeObj.aCoords.tl.x + ((canvas.width / scale) - activeObj.aCoords.br.x);
      }
      activeObj.set({
        left: left
      });
      break;
    case 'top':
      var top;
      if (activeObj.angle <= 90) {
        top = 0;
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.bl.y;
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.br.y;
      }
      if (activeObj.angle > 270) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.tr.y;
      }
      activeObj.set({
        top: top
      });
      break;
    case 'bottom':
      var top;
      if (activeObj.angle <= 90) {
        top = activeObj.aCoords.tl.y + ((canvas.height / scale) - activeObj.aCoords.br.y);
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        top = activeObj.aCoords.tl.y + ((canvas.height / scale) - activeObj.aCoords.tr.y);
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        top = canvas.height / scale;
      }
      if (activeObj.angle > 270) {
        top = activeObj.aCoords.tl.y + ((canvas.height / scale) - activeObj.aCoords.bl.y);
      }
      activeObj.set({
        top: top
      });
      break;
    case 'centerHorizontaly':
      activeObj.viewportCenterH(activeObj);
      break;
    case 'centerVertically':
      activeObj.viewportCenterV(activeObj);
      break;
  }
}

export const handleAlignment = (canvas, action, scale) => {
  const object = canvas.getActiveObject()
  if (object) {
    processAlign(canvas, action, object, scale)
    canvas.renderAll()
    canvas._historySaveAction();
  }
}

//Delete
export const handleDelete = (canvas) => {
  const object = canvas.getActiveObjects()
  object.map((value) => value.lock === false && canvas.remove(value))
  canvas.discardActiveObject().renderAll()
  //disable the autosave from the library and manually trigger it when deleting to avoid undo multi time when dealing with groups
  canvas._historySaveAction();
}

//Duplicate
export const handleDuplicate = (canvas) => {
  var isGroupSelected = false
  var groupObj = []
  canvas.getActiveObject().clone(
    function (clonedObj) {
      canvas.discardActiveObject()
      if (clonedObj.type == "activeSelection") {
        isGroupSelected = true
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas
        clonedObj.forEachObject(function (obj) {
          groupObj.push(obj)
          //remove obj from the group
          clonedObj.removeWithUpdate(obj)
          const allColors = obj?._objects?.map((v) => v.fill)
          const filteredColor = allColors?.filter(
            (v, i) => allColors?.indexOf(v) === i
          )
          const referenceColors = filteredColor?.map((v) =>
            allColors?.filter((value) => v === value)
          )
          let colorIndex = []
          filteredColor?.forEach((color) => {
            let initialIndex = []
            obj?._objects?.forEach((object, index) => {
              if (object.fill === color) initialIndex?.push(index)
            })
            colorIndex?.push(initialIndex)
          })
          const referenceColorIndex = colorIndex

          obj.name = obj.name + " copy"
          obj.id = obj.name + new Date().getTime()
          obj.referenceColorIndex = referenceColorIndex
          obj.referenceColor = referenceColors
          obj.set({
            left: obj.left + 20,
            top: obj.top + 40
          })
          canvas.add(obj)
        })
      } else {
        //signle object duplicate
        const allColors = clonedObj?._objects?.map((v) => v.fill)
        const filteredColor = allColors?.filter(
          (v, i) => allColors?.indexOf(v) === i
        )
        const referenceColors = filteredColor?.map((v) =>
          allColors?.filter((value) => v === value)
        )
        let colorIndex = []
        filteredColor?.forEach((color) => {
          let initialIndex = []
          clonedObj?._objects?.forEach((object, index) => {
            if (object.fill === color) initialIndex?.push(index)
          })
          colorIndex?.push(initialIndex)
        })
        const referenceColorIndex = colorIndex

        clonedObj.name = clonedObj.name + " copy"
        clonedObj.id = clonedObj.name + new Date().getTime()
        clonedObj.left = clonedObj.left + 10
        clonedObj.top = clonedObj.top + 10
        clonedObj.referenceColorIndex = referenceColorIndex
        clonedObj.referenceColor = referenceColors
        canvas.add(clonedObj)
      }

      //recreate selection if is was a group else select single object if isn't a group
      if (isGroupSelected) {
        var sel = new fabric.ActiveSelection(groupObj, {
          canvas: canvas
        })
        canvas.setActiveObject(sel)
      } else canvas.setActiveObject(clonedObj)
      canvas.requestRenderAll()
    },
    [
      "name",
      "id",
      "category",
      "parentCategory",
      "data",
      "data.data.text",
      "data.data.placeholder",
      "hasBorders",
      "borderColor",
      "cornerColor",
      "cornerSize",
      "transparentCorners",
      "cornerStyle",
      "lock",
      "lockMovementX",
      "lockMovementY",
      "selectable",
      "evented",
      "referenceColorIndex",
      "referenceColor"
    ]
  )
}

//Flip
export const handleFlip = (
  canvas,
  action
) => {
  const object = canvas.getActiveObject() 
  if (object) {
    if (action === "flipX") {
      if (object?.flipX) {
        object.set({ flipX: false }).setCoords()
      } else {
        object.set({ flipX: true }).setCoords()
      }
    } else if (action === "flipY") {
      if (object?.flipY) {
        object.set("flipY", false).setCoords()
      } else {
        object.set("flipY", true).setCoords()
      }
    }
  }
  canvas._historySaveAction()
  canvas.renderAll()
}

//Opacity
export const handleOpacity = (canvas, value) => {
  const objects = canvas.getActiveObjects()
  objects && objects.map((v) => v.set({ opacity: value }))

  canvas.renderAll()
}

//Position
export const handlePosition = (canvas, action) => {
  const object = canvas.getActiveObject()
  if (object) {
    if (action === "sendToBack") canvas.sendToBack(object)
    else if (action === "bringToFront") canvas.bringToFront(object)
    else if (action === "sendBackward") canvas.sendBackwards(object)
    else if (action === "bringForward") canvas.bringForward(object)
  }
  canvas._historySaveAction()
  canvas.renderAll()
}

export const handleLock = (canvas) => {
  const objects = canvas.getActiveObjects()
  if (objects) {
    objects.map((object) => {
      if (object.hasBorders) {
        object.set({
          hasControls: true,
          hasBorders: false,
          lockMovementY: false,
          lockMovementX: false,
          selectable: true,
          lock: false
        })
      } else {
        object.set({
          hasControls: false,
          hasBorders: true,
          lockMovementY: true,
          lockMovementX: true,
          borderColor: "blue",
          evented: true,
          editable: false,
          selectable: false,
          lock: true
        })
        canvas.discardActiveObject()
      }
    })
  }
  canvas._historySaveAction()
  canvas.renderAll()
}
