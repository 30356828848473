import React from "react"

function BackdropLoader() {
  return (
    <div
      className="absolute h-full w-full"
      style={{
        zIndex: "1001",
        background: "rgba(140, 147, 171, 0.5)"
      }}
    >
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex justify-center items-center">
          <div
            className={`animate-spin rounded-full h-24 w-24 border-b-2 border-grayEFEFEF`}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default BackdropLoader
