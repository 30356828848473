import React from 'react'

export default function Tooltip({placeholder,showTooltip}) {
    return (
     <div className="absolute z-50">
     {showTooltip && <div
         className="bg-grayEFEFEF tooltiptext text-purple2B3377 top-10 relative  text-xs  rounded-md px-2 py-1">
            {placeholder}       
        </div>}  
        </div>
    )
}
