//Packages
import { fabric } from "fabric"

export const handleBackground = (
  canvas,
  value,
  backgroundType,
  setLastBackgroundImage,
  canvasWidth,
  canvasHeight,
  isFilterSelected
) => {
  if (backgroundType === "color picker") {
    const image = new fabric.Image("")
    canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas))
    setLastBackgroundImage()
    canvas._historySaveAction()
    canvas.backgroundColor = `rgba(${value.r},${value.g},${value.b},${value.a})`
  } else if (backgroundType === "default color") {
    const image = new fabric.Image("")
    canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas))
    canvas.backgroundColor = value
    setLastBackgroundImage()
    canvas._historySaveAction()
  } else if (backgroundType === "image") {
    fabric.Image.fromURL(value, function (img) {
      var canvasAspect = canvasWidth / canvasHeight
      var imgAspect = img.width / img.height
      var left, top, scaleFactor

      if (canvasAspect >= imgAspect) {
        scaleFactor = canvasWidth / img.width
        left = 0
        top = -(img.height * scaleFactor - canvasHeight) / 2
      } else {
        scaleFactor = canvasHeight / img.height
        top = 0
        left = -(img.width * scaleFactor - canvasWidth) / 2
      }

      if (!isFilterSelected?.Grayscale) {
        img.filters.push(new fabric.Image.filters.Grayscale());
        img.applyFilters()
      }
      else if (!isFilterSelected?.Sepia) {
        img.filters.push(new fabric.Image.filters.Sepia());
        img.applyFilters()
      }
      else if (!isFilterSelected?.Kodachrome) {
        img.filters.push(new fabric.Image.filters.Kodachrome());
        img.applyFilters()
      }
      else if (!isFilterSelected?.Technicolor) {
        img.filters.push(new fabric.Image.filters.Technicolor());
        img.applyFilters()
      }
      else if (!isFilterSelected?.Polaroid) {
        img.filters.push(new fabric.Image.filters.Polaroid());
        img.applyFilters()
      }

      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        top: top,
        left: left,
        originX: "left",
        originY: "top",
        scaleX: Math.ceil(scaleFactor * 100) / 100,
        scaleY: Math.ceil(scaleFactor * 100) / 100
      })
      canvas._historySaveAction()
    })
  }
  canvas.renderAll()
}
