//Packages
import React from "react"

function Fabric(props) {
  const {
    // canvas,
    printBleed,
    canvasWidth,
    canvasHeight,
    triggerClick,
    zoomValue,
    ruler,
    createHorizontalLine,
    createVerticalLine,
    fullscreenFlag,
    widthRef,
    initialWidth
  } = props


  return (
    <div className="flex flex-col absolute z-0 inset-0">
      <div
        className="relative z-0 min-h-0"
        style={{
          flex: "1",
          contain: "content"
        }}
      >
        {ruler && (
          <div className="absolute border bg-white border-grayC4C4C4 z-0  h-full w-8">
            &nbsp;
          </div>
        )}
        <div
          className={`relative ${fullscreenFlag ? "overflow-hidden" : "overflow-scroll"
            } h-full `}
          id="scrolldiv"
          role="none"
          tabIndex="-1"
        >
          {ruler && (
            <div
              className={canvasWidth * zoomValue < initialWidth.current-100?"pl-10 border bg-white border-grayC4C4C4 z-50  w-full":"pl-10 border bg-white border-grayC4C4C4 z-50  w-screen"}
              onClick={createHorizontalLine}
            >
              <div
                className={canvasWidth * zoomValue < initialWidth.current-100?"mx-auto  transform -translate-x-2 flex h-8 overflow-x-hidden":"mx-20  transform -translate-x-2 flex h-8 overflow-x-hidden"}
                style={{
                  width: canvasWidth * zoomValue,
                  backgroundImage: "url(rulerpicHorizontal.svg)",
                  backgroundRepeatX: "repeat",
                  backgroundRepeatY: "no-repeat",
                  backgroundRepeat: "repeat-x",
                  backgroundSize: "49vw",
                  marginTop: "5px",
                  backgroundPositionX: "4px"
                }}
              ></div>
            </div>
          )}

          <div
            className="flex absolute min-w-full min-h-full"
            onClick={(e) => e.target.id === "parentDiv" && triggerClick()}
            ref={widthRef}
          >
            <div
              className="my-10 flex relative z-0 h-auto"
              id="parentDiv"
              style={{
                gridArea: "canvas",
                flex: 1,
                transform: "scale(1)"
              }}
            >
              {ruler && (
                <div
                  className="ml-1  w-10 my-auto  z-40 "
                  onClick={createVerticalLine}
                  style={{
                    height: canvasHeight * zoomValue,
                    backgroundImage: "url(rulerpicVertical.svg)",
                    backgroundRepeatY: "repeat",
                    backgroundRepeat: "repeat-y",
                    backgroundRepeatX: "no-repeat",
                    backgroundSize: "1.9vh",
                    backgroundPositionY: "0.5px"
                  }}
                ></div>
              )}
              <div className="relative m-auto flex flex-row justify-center flex-shrink-0">
                {/* leave a space between container and canvas when the div is scrollabel  */}
                <div className={canvasWidth * zoomValue < initialWidth.current-100 ? "" : "mx-20"}>
                  <div className="relative  ">
                    <div className="flex flex-col relative" id="jdfhdf">
                      <div className={`overflow-hidden bg-white `}>
                        <canvas
                          className={`${printBleed && window.parent == window
                            ? "border-2 border-red-500"
                            : "border-2 border-black"
                            } `}
                          id="canvas-main"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-44"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fabric
