export const zoom = (
  canvas,
  zoomValue,
  canvasWidth,
  canvasHeight,
) => {
  canvas.setZoom(zoomValue)
  canvas.setHeight(canvasHeight * zoomValue)
  canvas.setWidth(canvasWidth * zoomValue)

}
