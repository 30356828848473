//Packages
import { fabric } from "fabric"

import { handleBackground } from "../../utils/backgrounds/Background"

export const handleRotate = (
  canvas,
  setCanvasWidth,
  setCanvasHeight,
  canvasWidth,
  canvasHeight,
  printBleed,
  printBleedImg,
  lastBackgroundImage,
  setLastBackgroundImage,
  isFilterSelected
) => {

  const allObjects = canvas.getObjects()
  //centering objects
  allObjects.length > 0 &&
    allObjects.map((obj) => {
      if (obj.name == "GuidelinesX") {
        //changing height of guideline to fit new dimensions
        obj.height = canvasWidth;
      }
      else if (obj.name == "GuidelinesY") {
        //changing width of guideline to fit new dimensions
        obj.width = canvasHeight;
      }
      else
        canvas.viewportCenterObject(obj)
    })
  //changing dimensions
  canvas.setDimensions({ width: canvas.height, height: canvas.width })

  if (printBleed) {
    allObjects.map(
      (value) => value.name === "PrintBleed" && canvas.remove(value)
    )

    new fabric.Image.fromURL(printBleedImg, (img) => {
      var img1 = img.set({
        scaleX: canvasHeight / img.height,
        scaleY: canvasWidth / img.width,
        name: "PrintBleed",
        lock: true,
        hasBorders: false,
        selectable: false,
        hoverCursor: "default",
        lockMovementX: true,
        lockMovementY: true,
        opacity: 0.5
      })
      canvas.add(img1)
      img1.moveTo(-9999)
    })
  }

  if (lastBackgroundImage) {
    handleBackground(
      canvas,
      lastBackgroundImage,
      "image",
      setLastBackgroundImage,
      canvasHeight,
      canvasWidth,
      isFilterSelected
    )
  }

  canvas.renderAll()
  //switching setting state dimension
  setCanvasWidth(canvasHeight)
  setCanvasHeight(canvasWidth)
}

export const handlePrintBleed = (
  canvas,
  data,
  canvasWidth,
  canvasHeight,
  printBleed
) => {
  if (!printBleed) {
    var xhr = new XMLHttpRequest()
    xhr.open("GET", data)
    xhr.responseType = "blob"
    xhr.setRequestHeader('Access-Control-Allow-Origin', process.env.REACT_APP_URL);
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(this.response)
      trimWhiteSpace(imageUrl, (result) => {
        new fabric.Image.fromURL(imageUrl && result, (img) => {
          var img1 = img.set({
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
            name: "PrintBleed",
            lock: true,
            hasBorders: false,
            selectable: false,
            hoverCursor: "default",
            lockMovementX: true,
            lockMovementY: true,
            opacity: 0.5,
            evented: false,
            crossOrigin: "anonymous"
          })
          canvas.add(img1)
          img1.moveTo(-9999)

          canvas.renderAll()
        })
      })
    }
    xhr.send()
  } else {
    const object = canvas.getObjects()
    object.map((value) => value.name === "PrintBleed" && canvas.remove(value))
  }
}

const trimWhiteSpace = (data, callback) => {
  var myImage = new Image()
  myImage.crossOrigin = "Anonymous"
  myImage.onload = function () {
    let imgWidth = myImage.width
    let imgHeight = myImage.height
    var canvas = document.createElement("canvas")
    canvas.setAttribute("width", imgWidth)
    canvas.setAttribute("height", imgHeight)
    var context = canvas.getContext("2d")
    context.drawImage(myImage, 0, 0)

    var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
      data = imageData.data,
      getRBG = function (x, y) {
        var offset = imgWidth * y + x
        return {
          red: data[offset * 4],
          green: data[offset * 4 + 1],
          blue: data[offset * 4 + 2],
          opacity: data[offset * 4 + 3]
        }
      },
      isWhite = function (rgb) {
        // many images contain noise, as the white is not a pure #fff white
        return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200
      },
      scanY = function (fromTop) {
        var offset = fromTop ? 1 : -1

        // loop through each row
        for (
          var y = fromTop ? 0 : imgHeight - 1;
          fromTop ? y < imgHeight : y > -1;
          y += offset
        ) {
          // loop through each column
          for (var x = 0; x < imgWidth; x++) {
            var rgb = getRBG(x, y)
            if (!isWhite(rgb)) {
              if (fromTop) {
                return y
              } else {
                return Math.min(y + 1, imgHeight)
              }
            }
          }
        }
        return null // all image is white
      },
      scanX = function (fromLeft) {
        var offset = fromLeft ? 1 : -1

        // loop through each column
        for (
          var x = fromLeft ? 0 : imgWidth - 1;
          fromLeft ? x < imgWidth : x > -1;
          x += offset
        ) {
          // loop through each row
          for (var y = 0; y < imgHeight; y++) {
            var rgb = getRBG(x, y)
            if (!isWhite(rgb)) {
              if (fromLeft) {
                return x
              } else {
                return Math.min(x + 1, imgWidth)
              }
            }
          }
        }
        return null // all image is white
      }

    var cropTop = scanY(true),
      cropBottom = scanY(false),
      cropLeft = scanX(true),
      cropRight = scanX(false),
      cropWidth = cropRight - cropLeft,
      cropHeight = cropBottom - cropTop

    canvas.setAttribute("width", cropWidth)
    canvas.setAttribute("height", cropHeight)
    // finally crop the guy
    canvas
      .getContext("2d")
      .drawImage(
        myImage,
        cropLeft,
        cropTop,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      )

    let dataUrl = canvas.toDataURL()
    callback(dataUrl)
  }
  myImage.src = data
}
