//Packages
import React,{useState} from "react"

//Assets
import { ReactComponent as ArrowDown } from "@Assets/toolbarIcons/TextElement/arrowDown.svg"
import Tooltip from "../../popUp/Tooltip"

function FontFamily(props) {
  const { viewSettings, fontFamily, settingsFunctionality } = props
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div
      className="mx-2 flex justify-center items-center h-16 cursor-pointer"
      onClick={() => viewSettings("fontFamily")}
      onMouseEnter={()=>setShowTooltip(true)}
      onMouseLeave={()=>setShowTooltip(false)}
    >
      <div
        className={`border rounded-md py-1 w-52 ${settingsFunctionality === "fontFamily"
          ? "border-purple5F72BD"
          : "border-purple5F72BD "
          }`}
      >
        <div className="flex justify-between items-center">
          <span className="mx-2 h-6 overflow-hidden text-purple5F72BD font-Rubik font-medium">
            {fontFamily}
          </span>
          <ArrowDown className="fill-selected mx-2 " />
        </div>
      </div>
     {fontFamily.length>15? <Tooltip placeholder={fontFamily} showTooltip={showTooltip}/>:""}
    </div>
  )
}

export default FontFamily
